import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 156px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: ${({ pointer }) => (pointer ? "pointer" : "")};
`;

export { Wrapper, Legend };
