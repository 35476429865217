import styled from "styled-components";

const Filters = styled.div`
  display: flex;
  gap: 30px;
`;

const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 2rem 2rem 2rem;
`;

const DateContainer = styled.div`
  max-width: 220px;
  div.textfield-outer div.ragnarok.textfield-wrapper {
    font-size: 14px;
    border: ${({ border }) => (border ? "2px #c9a91ee6 solid" : "none")};
  }
`;

const Index = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SecondSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 60px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChartContainer = styled.div`
  width: 500px;
`;

export { FirstSection, Filters, DateContainer, Index, SecondSection, ChartContainer, ContentContainer };
