import React, { useState, useEffect } from "react";
import { Text } from "@aidron/aidron-ds";

import { getForecasts } from "../../../../services/ForecastService";
import { getAverageProductDrivers } from "../../../../services/ProductDriver";

import * as S from "./LevelDriversModal.style";

import ChartSeries from "../../ForecastChartCarousel/ProductButtons/ProductDriversButton/ProductDriversChart/ChartSeries/ChartSeries";
import ContentLoader from "../../../../components/ContentLoader/ContentLoader";
import ClientFlowSection from "../../../../components/ClientFlowSection/ClientFlowSection";

export default function LevelDriversModal({ productIds }) {
  const [drivers, setDrivers] = useState([]);
  const [clientFlow, setClientFlow] = useState("");

  const orderedDrivers = drivers?.sort((a, b) => b.value - a.value) || [];

  useEffect(() => {
    fetchDrivers();
  }, []);

  async function fetchDrivers() {
    const token = localStorage.getItem("token");

    const forecasts = await getForecasts(token, "productDrivers");

    const latestForecastId = forecasts[forecasts.length - 1].forecastId;

    const result = await getAverageProductDrivers(token, latestForecastId, productIds);

    const filteredDrivers = result.drivers.filter(driver => driver.driver !== "Fluxo de Clientes (Índices do Varejo no Brasil)");
    setDrivers(filteredDrivers);

    const clientFlowValue = result.drivers.find(
      driver => driver.driver === "Fluxo de Clientes (Índices do Varejo no Brasil)"
    ).value;
    setClientFlow(clientFlowValue);
  }

  return (
    <>
      <Text className="mt-2 mb-4">Abaixo, a relação dos fatores que impactam na venda e o percentual de impacto de cada um:</Text>
      <ContentLoader
        resultCondition={drivers}
        contentCondition={drivers?.length}
        renderError={() => <Text>Esta funcionalidade estará disponivel em breve.</Text>}
      >
        <S.OuterChartContainer>
          <S.InnerChartContainer>
            {orderedDrivers.map(driver => (
              <ChartSeries series={driver} key={driver.driver} />
            ))}
          </S.InnerChartContainer>
        </S.OuterChartContainer>

        <ClientFlowSection clientFlow={clientFlow} />
      </ContentLoader>
    </>
  );
}
