import React from "react";

import * as S from "./HelpModal.style";

import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function HelpModal({ indexes }) {
  return (
    <>
      {indexes.map((index, i) => (
        <S.IndexContainer key={i}>
          <CustomText weight="bold">{index.label}:</CustomText>
          <CustomText margin="0 0 0 10px" textAlign="justify">
            {index.text}
          </CustomText>
        </S.IndexContainer>
      ))}
    </>
  );
}
