import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const Button = styled.button`
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: #f26f21;
  margin-left: 5px;
  vertical-align: text-top;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;
  }
`;

export { Container, Button };
