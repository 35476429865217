import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  margin: 1rem;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const IndexesSection = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: 1.5rem;
`;

const OuterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const InnerButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export { OuterContainer, Header, IndexesSection, OuterButtonContainer, InnerButtonContainer };
