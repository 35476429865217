import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Card, Button, TextField, Heading, Badge } from "@aidron/aidron-ds";
import { i18n } from "../../translate/i18n";

import { doLogin } from "../../services/AuthService";
import { createUserEvent } from "../../services/UserEventsService";

const RECAPTCHA_SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY || "6LcTqNgjAAAAAOAy-lByk-5r-2bxGemdSRZCCgVv";

function Login() {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [disableLogin, setDisableLogin] = useState(true);

  function handleCaptcha() {
    setDisableLogin(!disableLogin);
  }

  function onSubmit(event) {
    event.preventDefault();

    doLogin(email, password)
      .then(response => {
        localStorage.setItem("token", response.token);

        const token = localStorage.getItem("token");
        createUserEvent(token, { event: "Login", route: location.pathname });

        history.push("/home");
      })
      .catch(err => {
        console.error(err);
        setError(`${i18n.t("login.error")}`);
      });
  }

  // Funções para que a tecla enter clique no botão de login
  const enterClick = useCallback(
    e => {
      if (e.keyCode === 13 && !disableLogin) {
        onSubmit(e);
      }
    },
    [email, password, disableLogin]
  );

  useEffect(() => {
    document.addEventListener("keydown", enterClick);
    return () => {
      document.removeEventListener("keydown", enterClick);
    };
  }, [enterClick]);

  return (
    <main className="vh-lg-100 mt-5 mt-lg-0 d-flex align-items-center">
      <div className="container">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="w-100 fmxw-500">
            <Card style={{ padding: "30px" }}>
              <div className="d-flex justify-content-center">
                <img className="mb-3" src="/img/favicon/ms-icon-150x150.png" alt="Aidron" width={150} />
              </div>
              <div className="text-center text-md-center mb-5 mt-md-2">
                <Heading size="md">{i18n.t("login.title")}</Heading>
              </div>
              <div className="d-flex flex-column no-wrap mb-2">
                <TextField
                  width="500"
                  iconLeft="mail"
                  label={i18n.t("login.emailLabel")}
                  type="email"
                  placeholder={i18n.t("login.emailPlaceholder")}
                  onChange={value => {
                    setEmail(value);
                  }}
                  autoFocus
                  required
                  size="lg"
                />
                <TextField
                  width="500"
                  iconLeft="lock"
                  label={i18n.t("login.passwordLabel")}
                  type="password"
                  placeholder={i18n.t("login.passwordPlaceholder")}
                  onChange={value => {
                    setPassword(value);
                  }}
                  required
                  size="lg"
                />
                <div className="d-flex justify-content-center mb-3">
                  <ReCAPTCHA sitekey={RECAPTCHA_SITEKEY} onChange={handleCaptcha} />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  className="d-flex justify-content-center w-50 loginButton g-recaptcha"
                  label={i18n.t("login.signIn")}
                  size="lg"
                  onClick={onSubmit}
                  disabled={disableLogin}
                />
              </div>
              {error ? (
                <Badge style={{ marginTop: "15px", padding: "12px" }} variant="danger">
                  {error}
                </Badge>
              ) : (
                <></>
              )}
            </Card>
          </div>
        </div>
      </div>
    </main>
  );
}
export default Login;
