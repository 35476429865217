import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Icon } from "@aidron/aidron-ds";
import { toNDecimalsString } from "../../../../utils/utils";

import { createUserEvent } from "../../../../services/UserEventsService";

import * as S from "./ListItem.style";

import TextAndLabelView from "./TextAndLabelView/TextAndLabelView";
import ProductHierarchyHeading from "../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import OpportunityAccordion from "./OpportunityAccordion/OpportunityAccordion";
import ActionModal from "./ActionModal/ActionModal";
import ButtonWithModal from "../../../../components/ButtonWithModal/ButtonWithModal";
import ReactivateModal from "./ReactivateModal/ReactivateModal";
import CardOutlined from "../../../../components/CardOutlined/CardOutlined";

export default function ListItem({ opportunity, fetchData, accordionButton, reactivateOpportunity, opportunities }) {
  const location = useLocation();
  const { id, productId, forecastId, type1, value, percent, status, removedDate } = opportunity;

  const [openAccordion, setOpenAccordion] = useState();

  const typeDetails = new Map([
    [
      "additionalSale",
      {
        title: "Venda Adicional",
        summary:
          "Esta oportunidade é mapeada sempre que o seu plano de vendas futuro esta abaixo do centro da demanda do cliente (a demanda do cliente por este produto pode ser observada em verde no gráfico).",
        percentLabel: "Aumento Esperado da Venda",
        icon: <S.SaleIcon />,
      },
    ],
    [
      "avoidLoss",
      {
        title: "Evitar Perda de Venda",
        summary:
          "Esta oportunidade é identificada sempre que as vendas dos últimos meses estiverem abaixo ou próximo do limite mínimo da demanda do cliente.",
        percentLabel: "Aumento Esperado da Venda",
        icon: <S.SaleIcon />,
      },
    ],
    [
      "stock",
      {
        title: "Otimizar a Compra",
        summary:
          "Esta oportunidade é mapeada sempre que o seu plano de vendas futuro está acima do limite máximo da demanda por este produto. No gráfico, este limite da demanda pode ser observado em verde.",
        percentLabel: "Redução da Compra Futura",
        icon: <S.StockIcon />,
      },
    ],
  ]);

  const opportunityDetails = getOpportunityDetails(type1);

  function getOpportunityDetails(type1) {
    return typeDetails.get(type1);
  }

  const statusDetails = new Map([
    ["active", "Ativo"],
    ["reactivated", "Reativado"],
    ["checkout", "Checkout"],
  ]);

  const statusText = statusDetails.get(status);

  const formattedRemovedDate = new Date(removedDate);
  const removedDateText = new Intl.DateTimeFormat("pt-BR").format(formattedRemovedDate);

  function handleAccordionButton() {
    const token = localStorage.getItem("token");
    if (!openAccordion) {
      createUserEvent(token, {
        event: "Detalhes de Oportunidade",
        route: location.pathname,
        productId: productId,
        forecastId: forecastId,
      });
    }
    setOpenAccordion(!openAccordion);
  }

  return (
    <>
      <Card>
        <S.Container sixthColumn>
          <S.ItemImage border>
            {opportunityDetails.icon}
            <ProductHierarchyHeading productId={productId} />
          </S.ItemImage>

          <S.Item textAlign border>
            <TextAndLabelView label="Oportunidade" labelSize="xs" text={opportunityDetails.title} />
          </S.Item>
          <S.Item textAlign border>
            <TextAndLabelView
              label="Percentual"
              labelSize="xs"
              heading={`${percent > 0 ? "↑" : "↓"} ${toNDecimalsString(Math.abs(percent), 0)}%`}
              contentSize="md"
            />
          </S.Item>
          <S.Item textAlign border>
            <TextAndLabelView
              label="Tamanho da Oportunidade"
              labelSize="xs"
              heading={`R$ ${toNDecimalsString(value, 0)}`}
              contentSize="md"
            />
          </S.Item>
          <S.Item border>
            {!reactivateOpportunity ? (
              <TextAndLabelView label="Status" labelSize="xs" text={statusText} textColor="orange" />
            ) : (
              <TextAndLabelView label="Exclusão" labelSize="xs" text={removedDateText} />
            )}
          </S.Item>
          <S.Item>
            {!reactivateOpportunity ? (
              <ButtonWithModal
                label="Ações..."
                type="outlined"
                modalWidth="650px"
                renderModalContent={({ closeModal }) => (
                  <ActionModal onClose={closeModal} opportunityId={id} fetchData={fetchData} status={status} />
                )}
              />
            ) : (
              <ButtonWithModal
                label="Reabrir"
                modalHeading="Deseja reabrir a oportunidade?"
                type="outlined"
                modalWidth="650px"
                renderModalContent={({ closeModal }) => (
                  <ReactivateModal closeModal={closeModal} reactivateOpportunity={() => reactivateOpportunity(id)} />
                )}
              />
            )}
          </S.Item>
        </S.Container>
        {accordionButton && (
          <S.ButtonAccordion onClick={handleAccordionButton} label="">
            <Icon size="16" icon={openAccordion === type1 ? "chevron-up" : "chevron-down"} />
          </S.ButtonAccordion>
        )}
      </Card>
      {openAccordion && (
        <CardOutlined>
          <OpportunityAccordion
            opportunity={opportunity}
            opportunities={opportunities}
            getOpportunityDetails={getOpportunityDetails}
          />
        </CardOutlined>
      )}
    </>
  );
}
