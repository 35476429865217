import React from "react";
import { Card } from "@aidron/aidron-ds";

import * as S from "./OpportunityAccordion.style";

import OpportunityChart from "./OpportunityChart/OpportunityChart";
import InformationsSection from "./InformationsSection/InformationsSection";

export default function OpportunityAccordion({ opportunity, opportunities, getOpportunityDetails }) {
  return (
    <>
      <S.Section>
        <InformationsSection
          opportunity={opportunity}
          opportunities={opportunities}
          getOpportunityDetails={getOpportunityDetails}
        />

        <S.ChartContainer>
          <Card style={{ width: "80%", height: "100%" }}>
            <OpportunityChart opportunity={opportunity} />
          </Card>
        </S.ChartContainer>
      </S.Section>
    </>
  );
}
