import React, { useState, useContext, useEffect, useMemo } from "react";

import { ForecastContext } from "../../../../../../../templates/context/ForecastContext";
import { getPredictions } from "../../../../../../../services/PredictionsService";
import { defineMonthColumns } from "../../../../../../../utils/utils";

import * as S from "./ProductAnalysisButton.style";

import ButtonWithModal from "../../../../../../../components/ButtonWithModal/ButtonWithModal";
import ProductAnalysisChart from "../../../../../../../components/ProductAnalysisChart/ProductAnalysisChart";

export default function ProductAnalysisButton({ productId }) {
  const { selectedForecast, historicForecasts } = useContext(ForecastContext);
  const [predictions, setPredictions] = useState([]);
  const [historicPredictions, setHistoricPredictions] = useState([]);

  const initialHistoricForecasts = historicForecasts.slice(-3);
  const initialHistoricForecastIds = initialHistoricForecasts.map(forecast => forecast.forecastId);

  const historicMonthsToDisplay = historicForecasts.filter(historicForecast =>
    initialHistoricForecastIds.includes(historicForecast.forecastId)
  );

  const monthColumns = useMemo(() => defineMonthColumns(predictions), [predictions]);

  useEffect(() => {
    fetchPredictions();
  }, []);

  async function fetchPredictions() {
    const token = localStorage.getItem("token");

    const predictions = await getPredictions(token, [selectedForecast.forecastId]);
    setPredictions(predictions.predictions);

    const historicPredictions = await getPredictions(token, initialHistoricForecastIds);
    setHistoricPredictions(historicPredictions.predictions);
  }

  return (
    <S.OuterContainer>
      <ButtonWithModal
        label="Análise Avançada"
        icon="arrow-up-right"
        renderModalContent={() => (
          <S.InnerContainer>
            <ProductAnalysisChart
              productId={productId}
              predictionData={predictions}
              historicMonthsToDisplay={historicMonthsToDisplay}
              monthColumns={monthColumns}
              allHistoricPredictions={historicPredictions}
            />
          </S.InnerContainer>
        )}
      />
    </S.OuterContainer>
  );
}
