import React, { useState, useContext, useMemo } from "react";
import { Menu, Avatar, Text, Icon, Dropdown, MenuItem } from "@aidron/aidron-ds";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { CompanyContext } from "../../templates/context/CompanyContext";
import { doLogout } from "../../services/AuthService";

function AccountHeaderMenu() {
  const { userInfo } = useContext(CompanyContext);
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();

  const imageUrl = useMemo(() => {
    const buffer = new Uint8Array(userInfo.file?.content.data);
    const blob = new Blob([buffer], { type: "image/jpeg" });
    return URL.createObjectURL(blob);
  }, [userInfo]);

  function onLogout(event) {
    doLogout(localStorage.getItem("token"))
      .then(response => cleanAndRedirect())
      .catch(error => {
        console.error(error);
        cleanAndRedirect();
      });
  }

  function cleanAndRedirect() {
    localStorage.removeItem("token");
    history.push("/");
  }

  function getName() {
    return userInfo.name.split(" ")[0];
  }

  return (
    <div className="ms-4 me-5">
      <Menu>
        <button
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
          className="border-0 bg-transparent"
        >
          <div className="d-flex align-items-center">
            <Avatar name={getName()} size="lg" src={imageUrl} className="border border-5 border-color-orange" />
            <div className="d-flex flex-column align-items-start ms-3">
              <Text className="color-white mb-0" weight="bold">
                {getName()}
              </Text>
              <Text size="xs" className="mt-0 color-white">
                {userInfo.company.name}
              </Text>
            </div>
            <Icon icon="chevron-down" className="color-white ms-3" />
          </div>
        </button>
        <Dropdown position="bottom" open={openMenu} setOpen={setOpenMenu}>
          <Link to="/configuracoes/minhaConta">
            <MenuItem>Configurações</MenuItem>
          </Link>
          {userInfo.company.id === 4 && (
            <Link to="/userActivity">
              <MenuItem>Atividade de Usuários</MenuItem>
            </Link>
          )}
          <MenuItem onClick={onLogout}>Sair</MenuItem>
        </Dropdown>
      </Menu>
    </div>
  );
}

export default AccountHeaderMenu;
