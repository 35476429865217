import React, { useContext } from "react";
import { ForecastContext } from "../../../../templates/context/ForecastContext";
import { toNDecimalsString } from "../../../../utils/utils";

import * as S from "./ProductIndexes.style";

import ProductIndexCard from "./ProductIndexCard/ProductIndexCard";
import IconButtonWithModal from "../../../../components/IconButtonWithModal/IconButtonWithModal";
import CustomText from "../../../../components/StyledComponents/CustomText/CustomText.style";
import HelpModal from "./HelpModal/HelpModal";

export default function ProductIndexes({ predictions }) {
  const { selectedForecast } = useContext(ForecastContext);

  const currentMonth = selectedForecast.month;
  const currentYear = selectedForecast.year;

  const currentMonthPrediction = predictions.find(
    prediction => prediction.month === currentMonth && prediction.year === currentYear
  );

  const indexes = [
    {
      label: "Desvio Executado x Demanda",
      name: "realizedVsDemandDeviation",
      text: "Média percentual dos desvios do realizado em relação à demanda do cliente nos últimos dois meses. Quanto maior for este indicador, menos previsível é este item e isso pode indicar problemas de operação que precisamos resolver para tornar nosso plano mais assertivo.",
      format: includeArrowAndPercent,
    },
    {
      label: "Variação da venda no último mês",
      name: "lastMonthSaleVariation",
      text: "Variação percentual entre a venda realizada no penúltimo e último mês. Quando um pico de variação ocorre (e não se trate de uma data especial que ocorreu entre um mês e outro) isso pode nos indicar um cenário de mudança repentina não prevista.",
      format: includeArrowUpAndPercent,
    },
    {
      label: "Probabilidade de problema na venda",
      name: "saleAnomalyProbability",
      text: "Este valor indica a probabilidade que a IA encontrou da venda histórica ter algum problema de operação, ou seja, se este índice for alto, isso indica que a variação da venda no último mês não reflete apenas um comportamento da demanda dos clientes, mas sim alguma ação da operação (ex.: mudança de preço, estratégia de sortimento, etc).",
      format: includePercentSymbol,
    },
    {
      label: "Diferença Acerto IA x Plano",
      name: "aiAccuracyOverClient",
      text: "Se o número for positivo, significa que IA acerta mais que o Plano nos últimos 6 meses. Se for zero, significa que não há diferença no total deste período. E se for negativo, isso significa que a IA acerta menos que o plano. No entanto se a assertividade for baixa e a probabilidade de problema for alta, possivelmente temos fatores de operação que não estão nas variáveis que a IA utiliza e temos de incluir para aperfeiçoar os modelos.",
      format: includePlusOrMinusAndPercent,
    },
  ];

  const indexesWithValues = indexes.map(index => {
    const value = currentMonthPrediction?.[index.name];
    const formattedValue = index.format && value ? index.format(value) : value;
    return { ...index, value: formattedValue };
  });

  function includeArrowUpAndPercent(value) {
    if (value >= 100) {
      return `↑ 100%`;
    } else {
      return includePercentSymbol(value);
    }
  }

  function includeArrowAndPercent(value) {
    if (value == 0 || (value >= -0.04 && value <= 0.04)) {
      return "0%";
    } else {
      const arrow = value > 0 ? "↑" : "↓";
      return `${arrow} ${includePercentSymbol(value)}`;
    }
  }

  function includePlusOrMinusAndPercent(value) {
    if (value == 0 || (value >= -0.04 && value <= 0.04)) {
      return "0%";
    } else {
      const plusOrMinus = value > 0 ? "+" : "-";
      return `${plusOrMinus} ${includePercentSymbol(Math.abs(value))}`;
    }
  }

  function includePercentSymbol(value) {
    return `${toNDecimalsString(value, 1).toLocaleString("pt-BR")}%`;
  }

  return (
    <S.OuterContainer>
      <S.ScrollContainer>
        <S.HeadingWrapper>
          <CustomText weight="bold" display="inline">
            ÍNDICES PARA APOIO NA ANÁLISE
          </CustomText>
          <IconButtonWithModal
            icon="help-circle"
            modalHeading="Ajuda: índices para apoio na análise"
            renderModalContent={() => <HelpModal indexes={indexes} />}
          />
        </S.HeadingWrapper>

        <S.CardsContainer>
          <S.CardsInnerContainer>
            {indexesWithValues.map((index, i) => (
              <ProductIndexCard key={i} index={index} />
            ))}
          </S.CardsInnerContainer>
        </S.CardsContainer>
      </S.ScrollContainer>
    </S.OuterContainer>
  );
}
