import styled from "styled-components";

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ScrollContainer = styled.div`
  overflow: scroll;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardsContainer = styled.div`
  overflow: scroll;
`;

const CardsInnerContainer = styled.div`
  padding: 0 1.5rem;
  margin-top: 1rem;
  display: flex;
  gap: 5px;
`;

export { OuterContainer, ScrollContainer, HeadingWrapper, CardsContainer, CardsInnerContainer };
