import styled from "styled-components";
import { Text } from "@aidron/aidron-ds";

const colors = [
  { name: "orange", code: "#e75d0d" },
  { name: "mediumGrey", code: "#5f5e5e" },
  { name: "white", code: "#ffffff" },
  { name: "orangeGrey", code: "#b19585" },
  { name: "darkYellow", code: "#c9a91ee6" },
];

function getColor(name) {
  const code = colors.find(color => color.name === name)?.code;
  return code ? code : "";
}

const CustomText = styled(Text)`
  color: ${({ color }) => getColor(color)};
  min-height: ${({ minHeight }) => (minHeight ? "29px" : "0px")};
  margin: ${({ margin }) => (margin ? margin : "")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "")};
  display: ${({ display }) => (display ? display : "")};
  text-decoration: ${({ textDecoration }) => (textDecoration ? textDecoration : "")};
  font-size: ${({ size }) => (size === "md" ? "15px" : "14px")};
  font-weight: ${({ weight }) => (weight === "bold" ? "600" : "400")};
`;

export default CustomText;
