import React, { useState } from "react";
import { Icon } from "@aidron/aidron-ds";

import * as S from "./IconButtonWithModal.style";

import ModalTemplate from "../ModalTemplate/ModalTemplate";

export default function IconButtonWithModal({ icon, modalHeading, renderModalContent }) {
  const [openModal, setOpenModal] = useState(false);

  function onCloseModal() {
    setOpenModal(false);
  }

  return (
    <S.Container>
      <S.Button onClick={() => setOpenModal(true)}>
        <Icon icon={icon} size={17} />
      </S.Button>
      {openModal && (
        <ModalTemplate width="700px" heading={modalHeading} closingFunction={onCloseModal}>
          {renderModalContent({ closeModal: onCloseModal })}
        </ModalTemplate>
      )}
    </S.Container>
  );
}
