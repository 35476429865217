import styled from "styled-components";
import { Heading } from "@aidron/aidron-ds";

const colors = [
  { name: "orange", code: "#e75d0d" },
  { name: "mediumGrey", code: "#5f5e5e" },
  { name: "white", code: "#ffffff" },
  { name: "orangeGrey", code: "#b19585" },
];

function getColor(name) {
  const code = colors.find(color => color.name === name)?.code;
  return code ? code : "";
}

const CustomHeading = styled(Heading)`
  color: ${({ color }) => getColor(color)};
  font-size: ${({ size }) => (size === "xs" ? "18px" : "")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
  margin: ${({ margin }) => (margin ? `${margin} !important` : "")};
`;

export default CustomHeading;
