import React, { useState, useEffect } from "react";
import { Text } from "@aidron/aidron-ds";

import { getProductDrivers } from "../../../../../../services/ProductDriver";
import { getForecasts } from "../../../../../../services/ForecastService";

import * as S from "./ProductDriversChart.style";

import ProductHierarchyHeading from "../../../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import ChartSeries from "./ChartSeries/ChartSeries";
import ContentLoader from "../../../../../../components/ContentLoader/ContentLoader";
import CustomHeading from "../../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import IconButtonWithModal from "../../../../../../components/IconButtonWithModal/IconButtonWithModal";
import ClientFlowSection from "../../../../../../components/ClientFlowSection/ClientFlowSection";

export default function ProductDriversChart({ productId }) {
  const [productDrivers, setProductDrivers] = useState([]);
  const [clientFlow, setClientFlow] = useState("");

  const orderedProductDrivers = productDrivers?.sort((a, b) => b.value - a.value) || [];

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const token = localStorage.getItem("token");

    const forecasts = await getForecasts(token, "productDrivers");

    const latestForecastId = forecasts[forecasts.length - 1].forecastId;

    const drivers = await getProductDrivers(token, latestForecastId, [productId]);

    const filteredDrivers = drivers.filter(driver => driver.driver !== "Fluxo de Clientes (Índices do Varejo no Brasil)");

    const result = filteredDrivers.length ? filteredDrivers : undefined;
    setProductDrivers(result);

    const clientFlowValue = drivers.find(driver => driver.driver === "Fluxo de Clientes (Índices do Varejo no Brasil)")?.value;
    setClientFlow(clientFlowValue);
  }

  return (
    <ContentLoader
      resultCondition={productDrivers}
      contentCondition={productDrivers?.length}
      renderError={() => <Text>Esta funcionalidade estará disponivel em breve.</Text>}
    >
      <S.HeadingContainer>
        <ProductHierarchyHeading productId={productId} />
      </S.HeadingContainer>
      <S.TitleContainer>
        <CustomHeading size="sm">Fatores que impactam a venda deste produto</CustomHeading>
        <IconButtonWithModal
          icon="help-circle"
          modalHeading="Ajuda: Fatores que impactam a venda deste produto"
          renderModalContent={() => (
            <Text>
              Esta análise identifica % de impacto de cada fator na venda deste produto. Ela é importante para dignóstico e
              proposição de ações que possam otimizar estoque e/ou aumentar as vendas.
            </Text>
          )}
        />
      </S.TitleContainer>
      <S.OuterChartContainer>
        <S.InnerChartContainer>
          {orderedProductDrivers.map(driver => (
            <ChartSeries series={driver} key={driver.driver} />
          ))}
        </S.InnerChartContainer>
      </S.OuterChartContainer>

      <ClientFlowSection clientFlow={clientFlow} />
    </ContentLoader>
  );
}
