import React, { useEffect, useState } from "react";
import { Select, SelectItem, Text, Card, TextField, Heading } from "@aidron/aidron-ds";
import { getUserEvents } from "../../services/UserEventsService";

import * as S from "./UserActivity.style";

import Default from "../../components/Default/Default";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import CustomHeading from "../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../components/StyledComponents/CustomText/CustomText.style";
import ResultError from "../../components/ResultError/ResultError";

export default function UserActivity() {
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const [allUserEvents, setAllUserEvents] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(["Todas"]);
  const [selectedUsers, setSelectedUsers] = useState(["Todos"]);
  const [selectedDates, setSelectedDates] = useState({
    initial: formatDate(firstDayOfCurrentMonth),
    end: formatDate(today),
  });

  const areDatesValid = {
    initial: isValidDate(selectedDates.initial),
    end: isValidDate(selectedDates.end),
  };

  const data = getPagesData();

  const loginEvents = userEvents.filter(event => event.event === "Login");

  useEffect(() => {
    fetchUserEvents();
  }, []);

  async function fetchUserEvents() {
    const token = localStorage.getItem("token");
    const fetchedEvents = await getUserEvents(token);
    setAllUserEvents(fetchedEvents);
  }

  function getCompanies() {
    const companies = allUserEvents.map(event => event.user.company.name);
    companies.unshift("Todas");
    return [...new Set(companies)];
  }

  function getUsers() {
    const users = allUserEvents.map(event => event.user.email);
    users.unshift("Todos");
    return [...new Set(users)];
  }

  useEffect(() => {
    let results = allUserEvents;

    if (!selectedCompanies.includes("Todas")) {
      results = results.filter(result => selectedCompanies.includes(result.user.company.name));
    }

    if (!selectedUsers.includes("Todos")) {
      results = results.filter(result => selectedUsers.includes(result.user.email));
    }

    if (areDatesValid.initial) {
      const parsedInitialDate = new Date(parseDate(selectedDates.initial));
      results = results.filter(result => {
        const resultDate = new Date(result.createdAt);
        return resultDate >= parsedInitialDate;
      });
    }

    if (areDatesValid.end) {
      const parsedEndDate = new Date(parseDate(selectedDates.end));
      parsedEndDate.setHours(23, 59, 59, 999);
      results = results.filter(result => {
        const resultDate = new Date(result.createdAt);
        return resultDate <= parsedEndDate;
      });
    }

    setUserEvents(results);
  }, [allUserEvents, selectedCompanies, selectedUsers, selectedDates]);

  function getPagesData() {
    const result = {};
    userEvents.forEach(event => {
      const pageName = event.event;
      if (pageName !== "Login") {
        result[pageName] = result[pageName] ? result[pageName] + 1 : 1;
      }
    });
    return result;
  }

  function getGraphData() {
    const sortedData = Object.fromEntries(Object.entries(data).sort(([, valueA], [, valueB]) => valueB - valueA));
    const xLabels = Object.keys(sortedData);
    const values = Object.values(sortedData);
    return { xLabels, values };
  }

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: getGraphData().xLabels,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Número de acessos",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y;
          },
          style: {
            fontSize: "12px",
            color: "#666666",
            fontWeight: "normal",
          },
          inside: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Acessos",
        data: getGraphData().values,
        color: "#f58b4d",
      },
    ],
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function parseDate(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  }

  function isValidDate(dateString) {
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const match = dateString.match(regex);

    if (!match) return false;

    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1;
    const year = parseInt(match[3], 10);

    const date = new Date(year, month, day);
    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
  }

  return (
    <Default>
      <PageHeading>Atividade de Usuários</PageHeading>

      <S.FirstSection>
        <S.Filters>
          <div>
            <Text size="sm" weight="bold" className="mb-2">
              Empresas
            </Text>
            <Select multiselect label="" handleChange={e => setSelectedCompanies(e)} selected={selectedCompanies}>
              {getCompanies().map(company => (
                <SelectItem key={company} value={company}>
                  {company}
                </SelectItem>
              ))}
            </Select>
          </div>

          <div>
            <Text size="sm" weight="bold" className="mb-2">
              Usuários
            </Text>
            <Select multiselect label="" handleChange={e => setSelectedUsers(e)} selected={selectedUsers}>
              {getUsers().map(user => (
                <SelectItem key={user} value={user}>
                  {user}
                </SelectItem>
              ))}
            </Select>
          </div>

          <S.DateContainer border={!areDatesValid.initial}>
            <TextField
              onChange={e => setSelectedDates({ ...selectedDates, initial: e })}
              label="Data de início"
              placeholder="dd/mm/aaaa"
              value={selectedDates.initial}
            />
            {!areDatesValid.initial && (
              <CustomText color="darkYellow" size="xs">
                Insira uma data no formato "dd/mm/aaaa" anterior a hoje.
              </CustomText>
            )}
          </S.DateContainer>

          <S.DateContainer border={!areDatesValid.end}>
            <TextField
              onChange={e => setSelectedDates({ ...selectedDates, end: e })}
              label="Data de fim"
              placeholder="dd/mm/aaaa"
              value={selectedDates.end}
            />
            {!areDatesValid.end && (
              <CustomText color="darkYellow" size="xs">
                Insira uma data no formato "dd/mm/aaaa" anterior ou igual a hoje.
              </CustomText>
            )}
          </S.DateContainer>
        </S.Filters>

        <Card style={{ height: "fit-content" }}>
          <S.Index>
            <Text size="sm" weight="bold" className="mb-2">
              Número de logins
            </Text>
            <CustomHeading size="sm" weight="bold" className="mb-2">
              {loginEvents.length.toString()}
            </CustomHeading>
          </S.Index>
        </Card>
      </S.FirstSection>

      {userEvents.length ? (
        <S.SecondSection>
          <Card style={{ height: "fit-content" }}>
            <S.ContentContainer>
              {getGraphData().values.length ? (
                <>
                  <Heading size="sm" className="mb-2">
                    Páginas e componentes visualizados
                  </Heading>
                  <S.ChartContainer>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                  </S.ChartContainer>
                </>
              ) : (
                <Heading size="sm">Os usuários selecionados não acessaram páginas da plataforma.</Heading>
              )}
            </S.ContentContainer>
          </Card>

          <Card>
            <S.ContentContainer>
              <Heading size="sm" className="mb-2">
                Atividades
              </Heading>

              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Empresa</th>
                    <th scope="col">Usuário</th>
                    <th scope="col">Data</th>
                    <th scope="col">Página ou componente</th>
                  </tr>
                </thead>
                <tbody>
                  {userEvents.map(event => (
                    <tr>
                      <td>{event.user.company.name}</td>
                      <td>{event.user.email}</td>
                      <td>{formatDate(event.createdAt)}</td>
                      <td>{event.event}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </S.ContentContainer>
          </Card>
        </S.SecondSection>
      ) : (
        <ResultError />
      )}
    </Default>
  );
}
