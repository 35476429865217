import styled from "styled-components";

const HeadingContainer = styled.div`
  margin-top: -20px;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

const OuterChartContainer = styled.div`
  width: 100%;
  overflow: scroll;
`;

const InnerChartContainer = styled.div`
  min-width: 600px;
  margin-top: 1.5rem;
`;

export { HeadingContainer, TitleContainer, OuterChartContainer, InnerChartContainer };
