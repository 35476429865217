import React from "react";
import { Text } from "@aidron/aidron-ds";

import { getMonthAbbreviation, toNDecimalsString } from "../../../../../../utils/utils";

import * as S from "./InformationsSection.style";

import CustomHeading from "../../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import IconButtonWithModal from "../../../../../../components/IconButtonWithModal/IconButtonWithModal";
import TextAndLabelView from "../../TextAndLabelView/TextAndLabelView";
import ButtonWithModal from "../../../../../../components/ButtonWithModal/ButtonWithModal";
import OpportunityCarousel from "./OpportunityCarousel/OpportunityCarousel";
import ProductAnalysisButton from "./ProductAnalysisButton/ProductAnalysisButton";

export default function InformationsSection({ opportunity, opportunities, getOpportunityDetails }) {
  const { id, productId, value, percent, text, type1, startDate, endDate } = opportunity;
  const opportunityDetails = getOpportunityDetails(type1);
  const percentLabel = getOpportunityDetails(type1).title;

  const otherOpportunities = opportunities?.filter(opportunity => opportunity.productId === productId && opportunity.id !== id);

  const otherOpportunitiesText =
    otherOpportunities?.length === 1 ? "Este produto possui outra oportunidade" : "Este produto possui outras oportunidades";

  const buttonLabel = otherOpportunities?.length === 1 ? "Analisar Oportunidade" : "Analisar Oportunidades";

  function dateToDisplay() {
    const start = adjustForTimezone(new Date(startDate));
    const end = adjustForTimezone(new Date(endDate));
    const startAbbreviation = getDateAbbreviation(start);

    if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
      return startAbbreviation;
    } else {
      const endAbbreviation = getDateAbbreviation(end);
      return `${startAbbreviation} a ${endAbbreviation}`;
    }
  }

  function adjustForTimezone(date) {
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + timezoneOffset);
  }

  function getDateAbbreviation(date) {
    const monthAbbreviation = getMonthAbbreviation(date.getMonth() + 1);
    const yearSuffix = date.getFullYear().toString().slice(2);
    return `${monthAbbreviation}/${yearSuffix}`;
  }

  return (
    <S.OuterContainer>
      <S.Header>
        <CustomHeading size="sm" margin="0">{`Oportunidade de ${opportunityDetails.title}`}</CustomHeading>
        <IconButtonWithModal
          icon="help-circle"
          modalHeading={`Ajuda: Oportunidade de ${opportunityDetails.title}`}
          renderModalContent={() => <Text>{opportunityDetails.summary}</Text>}
        />
      </S.Header>
      <S.IndexesSection>
        <TextAndLabelView
          label={percentLabel}
          heading={`${percent > 0 ? "↑" : "↓"} ${toNDecimalsString(Math.abs(percent), 0)}%`}
        />
        <TextAndLabelView label="Oportunidade" heading={`R$ ${toNDecimalsString(value, 0)}`} textSize="lg" />
        <TextAndLabelView label="Período" heading={dateToDisplay()} textSize="lg" />
      </S.IndexesSection>
      <div>
        <CustomHeading size="xs" margin="0">
          Recomendação de ação:
        </CustomHeading>
        <Text>{text.replace(/([.!?])\s/g, "$1\n")}</Text>
      </div>

      <ProductAnalysisButton productId={productId} />

      {opportunities && otherOpportunities.length > 0 && (
        <S.OuterButtonContainer>
          <S.InnerButtonContainer>
            <Text size="xs">{otherOpportunitiesText}</Text>
            <ButtonWithModal
              label={buttonLabel}
              type="outlined"
              modalWidth="90%"
              icon="arrow-up-right"
              renderModalContent={() => (
                <OpportunityCarousel otherOpportunities={otherOpportunities} getOpportunityDetails={getOpportunityDetails} />
              )}
            />
          </S.InnerButtonContainer>
        </S.OuterButtonContainer>
      )}
    </S.OuterContainer>
  );
}
