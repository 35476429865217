import React, { useContext, useEffect, useState, useMemo } from "react";
import { Text } from "@aidron/aidron-ds";

import { CompanyContext } from "../../../../../../templates/context/CompanyContext";
import { ForecastContext } from "../../../../../../templates/context/ForecastContext";
import { getForecasts } from "../../../../../../services/ForecastService";
import { getPredictions } from "../../../../../../services/PredictionsService";
import { defineMonthColumns } from "../../../../../../utils/utils";

import PredictionChart from "../../../../../../components/PredictionChart/PredictionChart";
import ProductHierarchyHeading from "../../../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import ContentLoader from "../../../../../../components/ContentLoader/ContentLoader";

export default function OpportunityChart({ opportunity, hideProductHierarchy }) {
  const { userInfo } = useContext(CompanyContext);
  const { historicForecasts } = useContext(ForecastContext);

  const [forecast, setForecast] = useState({});
  const [historicPredictions, setHistoricPredictions] = useState([]);
  const [predictionData, setPredictionData] = useState([]);

  const { forecastId, productId } = opportunity;

  const unit = userInfo.company.unit;

  const monthColumns = useMemo(() => {
    const predictionsWithClientValue = predictionData.filter(prediction => prediction.clientPrediction);
    return defineMonthColumns(predictionsWithClientValue);
  }, [predictionData]);

  useEffect(() => {
    fetchPredictions();
  }, []);

  async function fetchPredictions() {
    const token = localStorage.getItem("token");
    const historicForecastIds = defineHistoricMonths().map(month => month.forecastId);

    const [forecasts, historicPredictions, currentPredictions] = await Promise.all([
      getForecasts(token, "opportunities"),
      getPredictions(token, historicForecastIds),
      getPredictions(token, [forecastId]),
    ]);

    const forecast = forecasts.find(forecast => forecast.forecastId === forecastId);

    setForecast(forecast);
    setHistoricPredictions(historicPredictions.predictions);
    setPredictionData(currentPredictions.predictions);
  }

  function defineHistoricMonths() {
    const opportunityForecastIndex = historicForecasts.findIndex(
      historicForecast => historicForecast.month === forecast?.month && historicForecast.year === forecast?.year
    );

    const actualHistoricForecasts =
      opportunityForecastIndex === -1 ? historicForecasts : historicForecasts.slice(0, opportunityForecastIndex);

    return actualHistoricForecasts.slice(-3);
  }

  function defineFutureLines() {
    return [
      {
        label: "Plano Atual",
        name: "clientPrediction",
        lineStyle: "shortDash",
      },
    ];
  }

  return (
    <ContentLoader
      resultCondition={opportunity}
      contentCondition={historicPredictions.length}
      renderError={() => (
        <Text>Ocorreu um erro ao carregar o gráfico desta oportunidade. Por favor, contate o administrador do sistema.</Text>
      )}
    >
      {!hideProductHierarchy && <ProductHierarchyHeading productId={productId} />}
      <PredictionChart
        productId={productId}
        historicMonthsToDisplay={defineHistoricMonths()}
        monthColumns={monthColumns}
        predictionData={predictionData}
        unit={unit}
        allHistoricPredictions={historicPredictions}
        defineFutureLines={defineFutureLines}
        zeroPredictionIfNull
      />
    </ContentLoader>
  );
}
