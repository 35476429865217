import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createUserEvent } from "../../services/UserEventsService";

import * as S from "./ProductAnalysisChart.style";

import ProductHierarchyHeading from "../ProductHierarchyHeading/ProductHierarchyHeading";
import PredictionChart from "../PredictionChart/PredictionChart";
import ProductIndexes from "../../pages/PlanVsForecast/ForecastChartCarousel/ProductIndexes/ProductIndexes";
import ProductButtons from "../../pages/PlanVsForecast/ForecastChartCarousel/ProductButtons/ProductButtons";

export default function ProductAnalysisChart({
  predictionData,
  productId,
  historicMonthsToDisplay,
  monthColumns,
  allHistoricPredictions,
}) {
  const location = useLocation();
  function getProductPredictions(productId) {
    return predictionData.filter(prediction => prediction.productId === productId);
  }

  function defineFutureLines() {
    return [
      {
        label: "Plano Atual",
        name: "clientPrediction",
        lineStyle: "shortDash",
      },
      {
        label: "Forecast IA",
        name: "aidronPrediction",
        lineStyle: "shortDash",
      },
    ];
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    createUserEvent(token, { event: "Gráfico Análise Produto", route: location.pathname, productId: productId });
  }, []);

  return (
    <>
      <S.OuterChartContainer>
        <S.InnerChartContainer>
          <ProductHierarchyHeading productId={productId} />
          <PredictionChart
            productId={productId}
            historicMonthsToDisplay={historicMonthsToDisplay}
            monthColumns={monthColumns}
            predictionData={getProductPredictions(productId)}
            allHistoricPredictions={allHistoricPredictions}
            defineFutureLines={defineFutureLines}
          />
        </S.InnerChartContainer>
      </S.OuterChartContainer>
      <ProductIndexes predictions={getProductPredictions(productId)} />
      <ProductButtons productId={productId} />
    </>
  );
}
