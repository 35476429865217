import React, { useContext, useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ForecastContext } from "../../templates/context/ForecastContext";
import { CompanyContext } from "../../templates/context/CompanyContext";
import { getPredictions, getProductIds } from "../../services/PredictionsService";
import { createUserEvent } from "../../services/UserEventsService";

import AccuracyChartCarousel from "./AccuracyChartCarousel/AccuracyChartCarousel";
import AccuracyTable from "./AccuracyTable/AccuracyTable";
import Default from "../../components/Default/Default";
import NewTableOptions from "../../components/NewTableOptions/NewTableOptions";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import MonthColumnFilter from "../../components/MonthColumnFilter/MonthColumnFilter";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";

export default function Accuracy() {
  const location = useLocation();

  const { hierarchy, userInfo } = useContext(CompanyContext);
  const { historicForecasts, selectedForecast } = useContext(ForecastContext);

  const initialHistoricForecasts = historicForecasts.slice(-5);
  const initialHistoricForecastIds = initialHistoricForecasts.map(forecast => forecast.forecastId);

  const initialSortBy = {
    orderForecastId: selectedForecast.forecastId,
    columnLabel: "realized",
    months: [selectedForecast.month],
    year: selectedForecast.year,
    order: "descending",
  };

  const [historicPredictions, setHistoricPredictions] = useState([]);
  const [freezeColumns, setFreezeColumns] = useState(true);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [sortRowsBy, setSortRowsBy] = useState(initialSortBy);
  const [selectedHierarchyIndexes, setSelectedHierarchyIndexes] = useState(hierarchy.levels.map((level, index) => index));
  const [selectedHistoricForecastIds, setSelectedHistoricForecastIds] = useState(initialHistoricForecastIds);
  const [productIds, setProductIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState({});
  const [inputtedExpressions, setInputtedExpressions] = useState([]);

  const { day, month, year } = selectedForecast;
  const unit = userInfo.company.unit;
  const token = localStorage.getItem("token");

  const historicMonthsToDisplay = useMemo(() => {
    return historicForecasts.filter(historicForecast => selectedHistoricForecastIds.includes(historicForecast.forecastId));
  }, [selectedHistoricForecastIds]);

  useEffect(() => {
    fetchCurrentPredictions();
    createUserEvent(token, { event: "Página Assertividade Produto", route: location.pathname });
  }, []);

  useEffect(() => {
    fetchHistoricPredictions();
  }, [selectedHistoricForecastIds]);

  useEffect(() => {
    fetchProductIds();
  }, [sortRowsBy]);

  async function fetchCurrentPredictions() {
    const result = await getPredictions(token, [selectedForecast.forecastId]);
    setPredictions(result.predictions);
  }

  async function fetchHistoricPredictions() {
    const result = await getPredictions(token, selectedHistoricForecastIds);
    setHistoricPredictions(result.predictions);
  }

  async function fetchProductIds() {
    const result = await getProductIds(token, selectedForecast.forecastId, sortRowsBy);
    setProductIds(result.productIds);
    setSelectedProductIds(result.productIds);
  }

  return (
    <Default>
      <PageHeading>Analisar Assertividade Produto</PageHeading>
      <SectionHeader heading="Visão por produto" subtitles={[`Último forecast gerado em: ${day}/${month}/${year}`]} />
      <NewTableOptions
        checkedProducts={checkedProducts}
        selectedHierarchyIndexes={selectedHierarchyIndexes}
        setSelectedHierarchyIndexes={setSelectedHierarchyIndexes}
        freezeColumns={freezeColumns}
        setFreezeColumns={setFreezeColumns}
        productIds={productIds}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        renderHistoricFilter={({ closeModal }) => (
          <MonthColumnFilter
            historicForecasts={historicForecasts}
            onConcludedClick={closeModal}
            setSelectedHistoricForecastIds={setSelectedHistoricForecastIds}
            selectedHistoricForecastIds={selectedHistoricForecastIds}
          />
        )}
        renderGraphicAnalysis={() => (
          <AccuracyChartCarousel checkedProducts={checkedProducts} currentMonth={selectedForecast} unit={unit} />
        )}
        selectedLevels={selectedLevels}
        setSelectedLevels={setSelectedLevels}
        inputtedExpressions={inputtedExpressions}
        setInputtedExpressions={setInputtedExpressions}
      />
      <AccuracyTable
        freezeColumns={freezeColumns}
        setFreezeColumns={setFreezeColumns}
        monthColumns={historicMonthsToDisplay}
        currentMonth={selectedForecast}
        checkedProducts={checkedProducts}
        setCheckedProducts={setCheckedProducts}
        sortRowsBy={sortRowsBy}
        setSortRowsBy={setSortRowsBy}
        selectedHierarchyIndexes={selectedHierarchyIndexes}
        unit={unit}
        allHistoricPredictions={historicPredictions}
        selectedProductIds={selectedProductIds}
        predictionData={predictions}
      />
    </Default>
  );
}
