import styled from "styled-components";

const HeaderContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 3%;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 2%;
`;

const RemovedButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export { HeaderContent, RemovedButtonContainer };
